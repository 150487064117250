import React, {useContext, useState } from "react";
import './ExamContent.css';
import { ExamContext } from "../../Context/ExamsPageContext";
import axios from "axios";
import addQuestionLogo from "../../assets/add-question.png";

const API_URL = process.env.REACT_APP_API_URL;
function ExamContent() {
    const {CurrentExam, setCurrentExam, setExams} = useContext(ExamContext);
    const [newQuestionId, setNewQuestionId] = useState(0);
    const [newAnswerId, setNewAnswerId] = useState(0);

    const deleteExam = async () => {
        try {
            await axios.delete(`${API_URL}/private/exams/${CurrentExam.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setExams(prev => prev.filter(exam => exam.id !== CurrentExam.id));
            setCurrentExam(null);
        }
        catch (error) {
            console.log(error);
        }
    }

    const getNewQuestionId = () => {
        const newId = newQuestionId;
        setNewQuestionId(prev => prev - 1);
        return newId;
    }

    const getNewAnswerId = () => {
        const newId = newAnswerId;
        setNewAnswerId(prev => prev - 1);
        return newId;
    }

    const handleNewQuestion = () => {
        setCurrentExam({...CurrentExam, questions: [...CurrentExam.questions, { id: getNewQuestionId(), text: "Nueva pregunta", answers: []}]});
    }
    const handleNewAnswer = (questionIdx) => {
        const newAnswer = { id: getNewAnswerId(), text: "Nueva respuesta", correct: false};
        setCurrentExam({...CurrentExam, questions: CurrentExam.questions.map((question, idx) => {
            if (idx === questionIdx) {
                return {...question, answers: [...question.answers, newAnswer]};
            }
            return question;
        })});
    }
    return (
    <div className="exam-content">
        { CurrentExam === null ? <div><h1>Selecciona o crea un examen</h1></div> :
            <div>
                <button onClick={deleteExam}>Eliminar examen</button>
                <h1>{CurrentExam.name}</h1>
                { CurrentExam.questions === null ? <></> : <div>{CurrentExam.questions.map((question, idx) => {
                    return (
                        <div key={idx} className="question">
                            <h2>{question.text}</h2>
                            <div className="answers">
                                {question.answers.map((answer, idx) => {
                                    return (
                                        <div key={idx} className="answer">
                                            { answer.correct === true ? <p>V  {answer.text}</p> : <p>X  {answer.text}</p>}
                                        </div>
                                    );
                                })}
                            </div>
                            <button onClick={() => handleNewAnswer(idx)}>Agregar respuesta</button>
                        </div>
                    );
                }) }</div> }
                <div className="add-question">
                    <button onClick={handleNewQuestion}><img src={addQuestionLogo}></img></button>
                </div>
            </div>}
    </div>
  );
}

export default ExamContent;