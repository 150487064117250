// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar {
    width: 100%;
    height: 70px;
    background-color: rgb(35, 39, 47);
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(75, 74, 74);
    top: 0;
    position: fixed;
    z-index: 2;
}
.Navbar ul {
    list-style-type: none;
}

.Navbar li {
    margin: 0 30px;
    color: white;
    display: inline;
    font-size: larger;
    margin-left: auto;
}

.Navbar img {
    width: 70px;
    height: 60px;
    margin-left: 20px;
}
.Navbar a {
    text-decoration: none;
    color: white;
}

.Navbar p {
    color: white;
}

.navbar-user-info {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.navbar-user-info img {
    margin-right: 10px;
}

.navbar-user-info img:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,MAAM;IACN,eAAe;IACf,UAAU;AACd;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".Navbar {\n    width: 100%;\n    height: 70px;\n    background-color: rgb(35, 39, 47);\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid rgb(75, 74, 74);\n    top: 0;\n    position: fixed;\n    z-index: 2;\n}\n.Navbar ul {\n    list-style-type: none;\n}\n\n.Navbar li {\n    margin: 0 30px;\n    color: white;\n    display: inline;\n    font-size: larger;\n    margin-left: auto;\n}\n\n.Navbar img {\n    width: 70px;\n    height: 60px;\n    margin-left: 20px;\n}\n.Navbar a {\n    text-decoration: none;\n    color: white;\n}\n\n.Navbar p {\n    color: white;\n}\n\n.navbar-user-info {\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n}\n\n.navbar-user-info img {\n    margin-right: 10px;\n}\n\n.navbar-user-info img:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
