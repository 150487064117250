import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import examLogo from "../../assets/exam.svg";
import defaultAvatar from "../../assets/default-avatar.png";
function Navbar() {
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("userPicture");
        window.location.href = "/login";
    }
    const username = localStorage.getItem("username");
    const userPicture = localStorage.getItem("userPicture");
    return (
        <nav className="Navbar">
            <img src={examLogo} alt="Exam Logo" className="ExamLogo"/>
            <ul>
                <li><Link to="/examenes" >Mis Examenes</Link></li>
                <li><Link to="/examenes">Explorar</Link></li>
                <li><Link to="/examenes">Guardados</Link></li>
                <li><Link onClick={handleLogout}>Cerrar Sesión</Link></li>
            </ul>
            <div className="navbar-user-info">
                <p>{username}</p>
                <img src={defaultAvatar} alt="User Avatar"/>
            </div>
        </nav>
    );
}
export default Navbar;