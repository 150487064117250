import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./Home.css";
function Home() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="HomePage">
        <h1>Home</h1>
      </div>
    </div>
  );
}

export default Home;