// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage {
    background-color: rgb(39, 39, 39);
    height: 100vh;
}
.HomePage h1 {
    color: white;
    text-align: center;
    margin-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;AACjB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".HomePage {\n    background-color: rgb(39, 39, 39);\n    height: 100vh;\n}\n.HomePage h1 {\n    color: white;\n    text-align: center;\n    margin-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
