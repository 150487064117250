import React from "react";
import {jwtDecode} from "jwt-decode";

const Landing = () => {

    const token = localStorage.getItem("token");
    if (!token){
        window.location.href = "/login";
    }
    try {
        const {exp} = jwtDecode(token);
        if (Date.now() >= exp * 1000){
            localStorage.removeItem("token");
            window.location.href = "/login";
        }
        window.location.href = "/home";
    }
    catch (error) {
        console.log(error);
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
    return;
}

export default Landing;