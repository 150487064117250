import React, {useState} from "react";
import './Login.css';
import axios from "axios";
import logo from "../../assets/exam.svg";

const API_URL = process.env.REACT_APP_API_URL;
function Login() {
  const [pageState, setPageState] = useState("login");
  const [failed, setFailed] = useState("invisible");
  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    try {
      const response = await axios.post(`${API_URL}/public/login/login`, {email, password})
      const token = response.data.token;
      const username = response.data.user.username;
      const userPicture = response.data.user.userPicture;
      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      localStorage.setItem("userPicture", userPicture);
      window.location.href = "/home";
    }
    catch (error) {
      console.log(error);
      setFailed("visible");
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    
    const username = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    try {
      const response = await axios.post("http://localhost:3000/public/login/register", {username, email, password});
      alert("Usuario creado con exito");
      window.location.href = "/login";
    }
    catch (error) {
      console.log(error);
      setFailed("visible");
    }
  }
  return (
    <div className="LoginPage">
      <div className="Login-header">
        <img src={logo}/>
        <h1>MiExamen</h1>
      </div>
      <div className="Login-container">
        { pageState === "login" ? 
        <>
        <h2>Inicio de Sesion</h2>
        { failed === "visible" ? <p>Usuario o contraseña incorrectos</p> : <></> }
        <form onSubmit={handleLogin}>
          <input type="text" placeholder="Email" />
          <input type="password" placeholder="Contraseña" />
          <button type="submit">Iniciar Sesion</button>
        </form>
        </> 
        : 
        <>
          <h2>Registro</h2>
          <form onSubmit={handleRegister}>
            <input type="text" placeholder="Nombre de Usuario" />
            <input type="text" placeholder="Email" />
            <input type="password" placeholder="Contraseña" />
            <button type="submit">Registrarse</button>
          </form>
        </> }
        <div className="login-underline">
        { pageState === "login" ? 
        <p onClick={() => setPageState("register")}> ¿No tienes cuenta aún? <a>Registrate!</a></p> 
        : 
        <p onClick={() => setPageState("login")}>¿Ya tienes cuenta? <a>Inicia Sesion</a></p> }
        </div>
      </div>
    </div>
  );
}

export default Login;