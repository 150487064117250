// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exam-content{
    left: 200px;
    width: calc(100% - 200px);;
    top: 70px;
    position: absolute;
    border-top: 2px solid rgb(75, 74, 74);
    background-color: rgb(39, 39, 39);
    overflow-y: auto;
}
.exam-content h1{
    color: white;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 50px;
}
.question {
    width: 60vw;
    margin-left: 15vw;
    margin-bottom: 50px;
    background-color: rgb(35, 39, 47);
    border-radius: 40px;
    border: 1px inset cyan;
    padding: 30px;
    color: white;
}

.add-question{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 40px;
}

.add-question button{
    background-color: rgb(39, 39, 39);
    border: none;
    cursor: pointer;
}

.add-question img {
    height: 80px;
    width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ExamContent/ExamContent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,SAAS;IACT,kBAAkB;IAClB,qCAAqC;IACrC,iCAAiC;IACjC,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,iCAAiC;IACjC,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,iCAAiC;IACjC,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".exam-content{\n    left: 200px;\n    width: calc(100% - 200px);;\n    top: 70px;\n    position: absolute;\n    border-top: 2px solid rgb(75, 74, 74);\n    background-color: rgb(39, 39, 39);\n    overflow-y: auto;\n}\n.exam-content h1{\n    color: white;\n    text-align: center;\n    margin-top: 0px;\n    margin-bottom: 50px;\n}\n.question {\n    width: 60vw;\n    margin-left: 15vw;\n    margin-bottom: 50px;\n    background-color: rgb(35, 39, 47);\n    border-radius: 40px;\n    border: 1px inset cyan;\n    padding: 30px;\n    color: white;\n}\n\n.add-question{\n    display: flex;\n    justify-content: center;\n    margin-top: 50px;\n    padding-bottom: 40px;\n}\n\n.add-question button{\n    background-color: rgb(39, 39, 39);\n    border: none;\n    cursor: pointer;\n}\n\n.add-question img {\n    height: 80px;\n    width: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
