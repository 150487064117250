import React, {useContext, useEffect, useState} from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./MyExams.css";
import ExamList from "../../Components/ExamList/ExamList";
import { ExamContext } from "../../Context/ExamsPageContext";
import ExamContent from "../../Components/ExamContent/ExamContent";
function MyExams() {
    const {CurrentExam} = useContext(ExamContext);

    return (
        <div>
            <Navbar></Navbar>
            <div className="MyExamsPage">
                <div className="Exams">
                    <ExamList></ExamList>
                    <ExamContent exam={CurrentExam}></ExamContent>
                </div>
            </div>
        </div>
    );
}

export default MyExams;