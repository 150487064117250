import React, {useContext} from "react";
import './ExamList.css';
import { ExamContext } from "../../Context/ExamsPageContext";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function ExamList( ) {

    const {Exams, setCurrentExamId, setExams} = useContext(ExamContext);
    
    const handleNewExam = async () => {
        console.log("Creating new exam");
        try {
            const response = await axios.post(`${API_URL}/private/exams`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setCurrentExamId(response.data.id);
            setExams(prev => [...prev, response.data]);
        }
        catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="ExamList">
            <div className="ExamList-container">
                {Exams.map((exam) => {
                    return (
                        <div key={exam.id} className="Exam">
                            <button onClick={() => setCurrentExamId(exam.id)}>{exam.name}</button>
                        </div>
                    );
                })}
                <div className="add-new-exam">
                    <button onClick={handleNewExam} type="button">Añadir Nuevo</button>
                </div>
            </div>
        </div>
    );
}

export default ExamList;