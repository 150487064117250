// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage {
    background-color: rgb(39, 39, 39);
    height: 100vh;
}

.LoginPage img{
    width: 100px;
    height: 100px;
    display: block;
}

.LoginPage h1{
    color: white;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 50px;
    font-size: 60px;
}

.Login-header {
    padding-top: 30px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}
.LoginPage h2{
    margin-top: 0px;
    margin-bottom: 70px;
    text-align: center;
}

.Login-container {
    position: relative;
    width: 40vw;
    height: 60vh;
    margin-left: 30vw;
    padding: 30px;
    background-color: rgb(35, 39, 47);
    border-radius: 40px;
    border: solid 1px cyan;
    color: white;
}

.Login-container form {
    display: grid;
}

.Login-container input {
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    border: none;
}

.Login-container button {
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    border: none;
    background-color: cyan;
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.login-underline a {
    text-decoration: underline;
    cursor: pointer;
    color: cyan;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,iCAAiC;IACjC,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,WAAW;AACf","sourcesContent":[".LoginPage {\n    background-color: rgb(39, 39, 39);\n    height: 100vh;\n}\n\n.LoginPage img{\n    width: 100px;\n    height: 100px;\n    display: block;\n}\n\n.LoginPage h1{\n    color: white;\n    text-align: center;\n    margin-top: 0px;\n    margin-bottom: 50px;\n    font-size: 60px;\n}\n\n.Login-header {\n    padding-top: 30px;\n    padding-bottom: 50px;\n    display: flex;\n    justify-content: center;\n}\n.LoginPage h2{\n    margin-top: 0px;\n    margin-bottom: 70px;\n    text-align: center;\n}\n\n.Login-container {\n    position: relative;\n    width: 40vw;\n    height: 60vh;\n    margin-left: 30vw;\n    padding: 30px;\n    background-color: rgb(35, 39, 47);\n    border-radius: 40px;\n    border: solid 1px cyan;\n    color: white;\n}\n\n.Login-container form {\n    display: grid;\n}\n\n.Login-container input {\n    padding: 20px;\n    margin: 20px;\n    border-radius: 10px;\n    border: none;\n}\n\n.Login-container button {\n    padding: 20px;\n    margin: 20px;\n    border-radius: 10px;\n    border: none;\n    background-color: cyan;\n    color: black;\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.login-underline a {\n    text-decoration: underline;\n    cursor: pointer;\n    color: cyan;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
