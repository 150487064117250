import React, { createContext, useState, useEffect} from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export const ExamContext = createContext();

export const ExamProvider = ({children}) => {
    const [CurrentExam, setCurrentExam] = useState(null);
    const [CurrentExamId, setCurrentExamId] = useState(null);
    const [Exams, setExams] = useState([]);

    useEffect( () => {
        async function fetchExams() {
            try {
                const response = await axios.get(`${API_URL}/private/exams`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                setExams(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchExams();
    }, []);
    useEffect( () => {
        async function fetchCurrentExam() {
            try {
                if (CurrentExamId === null) {
                    return;
                }
                const response = await axios.get(`${API_URL}/private/exams/${CurrentExamId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                setCurrentExam(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchCurrentExam();
    }, [CurrentExamId]);

    return (
        <ExamContext.Provider value={{CurrentExam, setCurrentExam, Exams, setExams, setCurrentExamId, CurrentExamId}}>
            {children}
        </ExamContext.Provider>
    );
};

