// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyExamsPage {
    background-color: rgb(39, 39, 39);
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/MyExams/MyExams.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;AACjB","sourcesContent":[".MyExamsPage {\n    background-color: rgb(39, 39, 39);\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
