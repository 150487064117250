// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExamList {
    margin: 0;
    padding: 0;
    width: 200px;
    height: calc(100vh - 70px - 40px);
    position: fixed;
    overflow-y: auto;
    background-color: #212b3a;
    border: 1px solid rgb(75, 74, 74);
    z-index: 1;
    top: 70px;
    bottom: 90px;
}
.Exam {
    display: flex;
    justify-content: center;
    height: 50px;
}
.Exam button {
    width: 100%;
    background-color: rgb(72, 166, 187);
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
}

.add-new-exam button{
    cursor: pointer;
    bottom: 0;
    left: 0;
    position: fixed;
    
    border: 1px solid rgb(0, 0, 0);
    border-right: 1px solid rgb(75, 74, 74);
    border-left: 1px solid rgb(75, 74, 74);
    height: 40px;
    width: 202px;
    background-color: rgb(94, 110, 158);
}`, "",{"version":3,"sources":["webpack://./src/Components/ExamList/ExamList.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,iCAAiC;IACjC,UAAU;IACV,SAAS;IACT,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,WAAW;IACX,mCAAmC;IACnC,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,eAAe;;IAEf,8BAA8B;IAC9B,uCAAuC;IACvC,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,mCAAmC;AACvC","sourcesContent":[".ExamList {\n    margin: 0;\n    padding: 0;\n    width: 200px;\n    height: calc(100vh - 70px - 40px);\n    position: fixed;\n    overflow-y: auto;\n    background-color: #212b3a;\n    border: 1px solid rgb(75, 74, 74);\n    z-index: 1;\n    top: 70px;\n    bottom: 90px;\n}\n.Exam {\n    display: flex;\n    justify-content: center;\n    height: 50px;\n}\n.Exam button {\n    width: 100%;\n    background-color: rgb(72, 166, 187);\n    border: 1px solid rgb(0, 0, 0);\n    cursor: pointer;\n}\n\n.add-new-exam button{\n    cursor: pointer;\n    bottom: 0;\n    left: 0;\n    position: fixed;\n    \n    border: 1px solid rgb(0, 0, 0);\n    border-right: 1px solid rgb(75, 74, 74);\n    border-left: 1px solid rgb(75, 74, 74);\n    height: 40px;\n    width: 202px;\n    background-color: rgb(94, 110, 158);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
