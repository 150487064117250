import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import MyExams from './Pages/MyExams/MyExams';
import Landing from './Pages/Landing';
import { ExamProvider } from './Context/ExamsPageContext';
function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={
            <ExamProvider><Home /></ExamProvider>} />
          <Route path="/login" element={<Login />} />
          <Route path="/examenes" element={<ExamProvider><MyExams /></ExamProvider>} />
        </Routes>
    </Router>
  );
}

export default App;
